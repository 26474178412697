// ----------------------------------------------------------------

.fix {
  overflow: hidden;
}

.bg-image {
  background-image: url("../images/wlc-bg1.jpg");
  // background-position: 50%;
  background-repeat: no-repeat;
  -webkit-background-size: cover;
  -moz-background-size: cover;
  -o-background-size: cover;
  background-size: cover;
  height: 100vh;
}

.home-row {
  height: 100vh;
  align-items: center;
}

.intro-section {
  color: white;

  h1 {
    font-size: 4rem;
  }
  h2 {
    font-size: 3rem;
  }
  h3 {
    font-size: 2rem;
  }
  h5 {
    font-size: 1.5rem;
  }
}

// -----------------------------------

.about {
  min-height: 100vh;
  // background: #0b0d0e;
  position: relative;
  background: url("../images/about-bg.png") no-repeat center center fixed;
  -webkit-background-size: cover;
  -moz-background-size: cover;
  -o-background-size: cover;
  background-size: cover;
  display: flex;
  align-items: center;
}
.skills-img {
  position: absolute;
  top: 0;
  z-index: 0;
}
.resume-section {
  margin-top: 1rem;
  h6 {
    margin-bottom: 0;
    margin-right: 1rem;
  }
  a {
    background: linear-gradient(to right, #56ccf2, #2f80ed);
    padding: 0.1rem 0.5rem;
    border-radius: 5px;
    text-decoration: none;
    color: white;
    font-weight: bold;
  }
}

.about-img {
  height: 60vh;
  width: 60vh;
  object-fit: cover;
  border-radius: 1rem;
}

.about-info {
  display: flex;
  flex-direction: column;
  justify-content: center;
  color: white;
}

.tabs {
  display: flex;
  justify-content: center;
}
.tab {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0.6rem;
  border-radius: 10rem;
  cursor: pointer;
  max-width: 13rem;
  margin-bottom: 1.5rem;

  h5 {
    margin: 0 !important;
    margin-bottom: 4px !important;
    margin-left: 1rem !important;
  }
}
.custom-mr-3 {
  margin-right: 1.5rem;
}
.custom-ml-3 {
  margin-left: 1.5rem;
}
.active-tab {
  // background-color: #09c75ea4;
  position: relative;
  background: linear-gradient(to right, #56ccf2, #2f80ed);
  &::before,
  &::after {
    content: "";
    position: relative;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    background: linear-gradient(to right, #56ccf2, #2f80ed);
    border-radius: 10rem;
    transition: all 0.5s ease-in-out;
  }
  &:hover::before {
    filter: blur(5px);
  }
  &:hover::after {
    filter: blur(15px);
  }
  &:hover {
    animation: hue 2s linear infinite;
  }
  @keyframes hue {
    0% {
      filter: hue-rotate(0deg);
    }
    100% {
      filter: hue-rotate(360deg);
    }
  }
}
.inactive-tab {
  border: 1px solid #2f80ed;
  position: relative;
  &::before,
  &::after {
    content: "";
    position: relative;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    border: 1px solid #2f80ed;
    border-radius: 10rem;
    transition: all 0.5s ease-in-out;
  }
  &:hover::before {
    filter: blur(5px);
  }
  &:hover::after {
    filter: blur(15px);
  }
  &:hover {
    animation: hue 2s linear infinite;
  }
  @keyframes hue {
    0% {
      filter: hue-rotate(0deg);
    }
    100% {
      filter: hue-rotate(360deg);
    }
  }
}
.about-sub-data {
  padding: 10px;
  background: rgba(51, 0, 255, 0.35);
  box-shadow: 0 8px 32px 0 rgba(31, 38, 135, 0.37);
  backdrop-filter: blur(11.5px);
  -webkit-backdrop-filter: blur(11.5px);
  border-radius: 10px;
  border: 1px solid rgba(255, 255, 255, 0.18);
}
.jdnkcjd {
  border-radius: 50px;
  margin-right: 8px;
  color: white;
  width: 70px;
  background: linear-gradient(
    135deg,
    rgba(250, 25, 9, 0.22),
    rgba(250, 0, 0, 0.25)
  );
}

@media (min-width: 700px) {
  body {
    grid-template-columns: repeat(3, 200px);
  }
}
.lmckdncd {
  border-radius: 50px;
  margin-right: 8px;
  color: white;
  width: 70px;
  background: linear-gradient(
    135deg,
    rgba(0, 0, 0, 0.22),
    rgba(255, 255, 255, 0.25)
  );
}

@media (min-width: 700px) {
  body {
    grid-template-columns: repeat(3, 200px);
  }
}
// -------------------------------------------------

.projects {
  background: url("../images/project-bg.jpg") no-repeat center center fixed;
  -webkit-background-size: cover;
  -moz-background-size: cover;
  -o-background-size: cover;
  background-size: cover;
}

.projects1 {
  background: url('https://images4.alphacoders.com/173/thumb-1920-173996.jpg')
    no-repeat center center fixed;
  -webkit-background-size: cover;
  -moz-background-size: cover;
  -o-background-size: cover;
  background-size: cover;
}

.heading-section {
  text-align: center;
  margin-bottom: 4rem;
  color: white;
}

.contact-section-bg {
  background: url("../images/map.png") no-repeat center center;
  background-color: #0b0d0e;
  background-repeat: no-repeat;
  background-size: cover;
  object-fit: cover;
  color: white;
}

.form-textfield-input {
  color: white !important;
}
.form-textfield {
  border-color: aliceblue !important;
}
.send-message-btn {
  padding: 15px 30px !important;
  span {
    font-weight: bold !important;
    font-size: 1.2rem;
  }
}

.project-card {
  position: relative;
  overflow: hidden;
  background: #2f81ed3f;
  box-shadow: 1px 2px 17px 0px rgba(0, 0, 0, 0.18);
  -webkit-box-shadow: 1px 2px 17px 0px rgba(0, 0, 0, 0.18);
  -moz-box-shadow: 1px 2px 17px 0px rgba(0, 0, 0, 0.18);
  background: rgba(191, 191, 191, 0.35);
  box-shadow: 0 8px 32px 0 rgba(31, 38, 135, 0.37);
  // backdrop-filter: blur(11.5px);
  // -webkit-backdrop-filter: blur(11.5px);
  border-radius: 10px;
  border-radius: 1rem;
  -webkit-border-radius: 1rem;
  -moz-border-radius: 1rem;
  overflow: hidden;
  -webkit-transition: all 300ms ease-out;
  -moz-transition: all 300ms ease-out;
  -o-transition: all 300ms ease-out;
  transition: all 300ms ease-out;
  &:hover {
    -webkit-box-shadow: 0px 6px 13px -2px rgba(0, 0, 0, 0.2);
    box-shadow: 0px 6px 13px -2px rgba(0, 0, 0, 0.2);
    transform: scale(1.02);

    .img-section {
      img {
        opacity: 0.8;
        -webkit-transform: scale(1);
        transform: scale(1);
      }
      .imageCardEffect::before {
        -webkit-transform: rotate(45deg) scale(2) translate(0px, 100%);
        -moz-transform: rotate(45deg) scale(2) translate(0px, 100%);
        -o-transform: rotate(45deg) scale(2) translate(0px, 100%);
        -ms-transform: rotate(45deg) scale(2) translate(0px, 100%);
        transform: rotate(45deg) scale(2.5) translate(0px, 100%);
      }
    }
  }
}
.project-card1 {
  background: rgba(255, 255, 255, 0.25);
  box-shadow: 0 8px 32px 0 rgba(147, 247, 89, 0.37);
  backdrop-filter: blur(20px);
  -webkit-backdrop-filter: blur(20px);
  border-radius: 10px;
  border: 1px solid rgba(124, 231, 75, 0.18);
}

.img-section {
  position: relative;
  overflow: hidden;

  img {
    position: relative;
    display: block;
    height: 14rem;
    width: 100%;
    object-fit: cover;
    margin: 0.5rem;
    border-radius: 10px;
    -webkit-transition: opacity 0.35s, -webkit-transform 0.35s;
    transition: opacity 0.35s, transform 0.35s;
    -webkit-transform: scale(1.15);
    transform: scale(1.15);
  }
  .imageCardEffect {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    padding: 30px 10px;
    -webkit-backface-visibility: hidden;
    -moz-backface-visibility: hidden;
    backface-visibility: hidden;
    text-indent: 9999px;
    &::before,
    &::after {
      pointer-events: none;
    }
    &::before {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      background: rgba(255, 255, 255, 0.5);
      content: "";
      -webkit-transition: -webkit-transform 0.6s;
      transition: transform 0.6s;

      -webkit-transform: rotate(45deg) scale(2) translate(0px, -100%);
      -moz-transform: rotate(45deg) scale(2) translate(0px, -100%);
      -o-transform: rotate(45deg) scale(2) translate(0px, -100%);
      -ms-transform: rotate(45deg) scale(2) translate(0px, -100%);
      transform: rotate(45deg) scale(2.5) translate(0px, -100%);
    }
  }
}

.project-info {
  padding: 1rem;
}
.project-info1 {
  padding: 1rem;
  border-radius: 3px;
  background: linear-gradient(145deg, #0c0c0c, #0e0e0e);
  box-shadow:  22px 22px 44px #0b0b0b,
               -22px -22px 44px #0f0f0f;
}
.skills-section {
  background: url("../images/about-bg-shape.png") no-repeat left bottom;
  background-color: #0b0d0e;
  padding-top: 6rem;
  padding-bottom: 10rem;
}

.skills {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  &:hover {
    img {
      -webkit-box-shadow: 0px 6px 13px -2px rgba(0, 0, 0, 0.2);
      box-shadow: 0px 6px 13px -2px rgba(0, 0, 0, 0.2);
      transform: scale(1.2);
    }
  }

  img {
    height: 3rem;
    width: 3rem;
    object-fit: contain;
    margin-bottom: 1rem;
    transition: all 0.3s ease-in-out;
  }

  h6 {
    color: white;
  }
}

.color-white {
  color: white;
}

.project-card-btn {
  background: rgba(144, 19, 254, 0.35);
  // box-shadow: 0 8px 32px 0 rgba(31, 38, 135, 0.37);
  // backdrop-filter: blur(11.5px);
  box-shadow: 12px 12px 16px 0 rgba(0, 0, 0, 0.25),
    -3px -3px 12px 0 rgba(255, 255, 255, 0.3);
  // -webkit-backdrop-filter: blur(11.5px);
  border-radius: 10px;
  border: 1px solid rgba(255, 255, 255, 0.18);
  position: relative;
  padding: 5px 10px;
  border-radius: 5px;
  color: white;
  text-decoration: none !important;
  cursor: pointer;

  &::before,
  &::after {
    content: "";
    position: relative;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    background: linear-gradient(to right, #56ccf2, #2f80ed);
    border-radius: 5px;
    transition: all 0.5s ease-in-out;
  }
  &:hover::before {
    filter: blur(5px);
  }
  &:hover::after {
    filter: blur(15px);
  }
  &:hover {
    animation: hue 2s linear infinite;
    color: white;
  }
  @keyframes hue {
    0% {
      filter: hue-rotate(0deg);
    }
    100% {
      filter: hue-rotate(360deg);
    }
  }
}

@media (max-width: 768px) {
  .custom-mr-3 {
    margin-right: 0 !important;
  }
  .custom-ml-3 {
    margin-left: 0 !important;
  }
  .home-page-intro {
    padding-top: 3rem !important;
  }
  .intro-section {
    h1 {
      font-size: 3rem !important;
    }
    h2 {
      font-size: 2rem !important;
    }
  }
}
