body, html {
    padding: 0;
    margin: 0;
}

.page {
    width: 100%;
    position: relative;
}

.bg-dkblue {
    background: linear-gradient(rgba(0, 0, 0, 0.6), rgba(0, 0, 0, 0.6));
    background: #053140;
    /* blish */
    background: #5c0b6b;
    /* prplish */
    background: #300A24;
    background-size: cover;
    height: 100vh;
    overflow: hidden;
    width: 100%;
}

.transparent-img {
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    width: 270px;
    height: 150px;
    position: absolute;
    bottom: 10px;
    right: 20px;
    opacity: 0.4;
    -webkit-animation: blink 2s ease-in 1s infinite alternate;
    -o-animation: blink 2s ease-in 1s infinite alternate;
    animation: blink 2s ease-in 1s infinite alternate;
}

#exitLanding {
    background: transparent;
    border: 2px solid #fff;
    /*width: 120px;*/
    height: 40px;
    position: absolute;
    bottom: 10px;
    left: 30px;
    color: #fff;
    border-radius: 0px;
}

@font-face {
    font-family: 'ailerons';
    font-weight: normal;
    font-style: normal;
}

.main-name {
    /*font-family: 'ailerons' !important;*/
    /*font-family: monospace;*/
    font-family: "Helvetica Neue", sans-serif !important;
    font-size: 42px;
    letter-spacing: 8px;
    padding: 24px 30px;
    color: #555;
    color: #5c0b6b;
    /*background: #f8f8f8;*/
    box-shadow: 0 50px 80px 0px rgba(0, 0, 0, 0.25);
    position: relative;
    z-index: 1;
    background: linear-gradient(to right, #fff 10%, #e6e6e6);
}

.typer {
    padding-left: 30px;
    padding-top: 70px;
}

.typer>p {
    font-family: monospace;
    line-height: 1.7em;
    /*border-right: 1px solid #000;*/
    font-size: 20px;
    color: #fff;
}

@media screen and (max-width: 600px) {
    .main-name {
        padding: 20px 30px;
        font-size: 32px;
        word-spacing: 100000000px;
    }
    .transparent-img {
        background-size: contain;
        width: 170px;
        height: 110px;
    }
    .typer {
        padding-top: 40px;
    }
    .typer>p {
        font-size: 24px;
        line-height: 1.2em;
    }
}


/*
* use typer.js to add an interaction first like --->

     
*/


/* CSS Solution Not good enough*/

.typed-text>p {
    font-family: monospace;
    line-height: 1.2em;
    border-right: 1px solid #000;
    font-size: 30px;
    color: #fff;
    /*background: #e9e9e9;*/
    width: 10em;
    white-space: nowrap;
    overflow: hidden;
    -webkit-animation: type 3.4s steps(40, end) infinite;
    -o-animation: type 3.4s steps(40, end) infinite;
    animation: type 3.4s steps(40, end) infinite;
}

@keyframes blink {
    from {
        opacity: 0.6;
    }
}

@-webkit-keyframes blink {
    from {
        opacity: 0.6;
    }
}

@-o-keyframes blink {
    from {
        opacity: 0.6;
    }
}

@keyframes type {
    from {
        width: 0;
        /*        border-right: none; 
*/
    }
}

@-webkit-keyframes type {
    from {
        width: 0;
        /*        border-right: none; 
*/
    }
}

@-o-keyframes type {
    from {
        width: 0;
        /*        border-right: none; 
*/
    }
}
